import React, { useState } from "react";
import { DatePicker, Modal, message } from "antd";
import dayjs from "dayjs";
import { changeLicenseDate } from "../api/db-api";

export const ModalChangeLicenseDate = (props) => {
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const handleCancel = () => {
    props.setIsModalChangeLicenseDateOpen(false);
  };

  const handleOk = async () => {
    const result = await changeLicenseDate(
      props.users.map((user) => user.id),
      selectedDate.format("YYYY-MM-DD")
    );
    props.setIsModalChangeLicenseDateOpen(false);
    if (result.status === "success") {
      return message.success("Оновлено!");
    }
    message.error("Помилка оновлення дати, " + result.message);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };

  return (
    <>
      <Modal
        title={"Зміна терміну дії ліцензії"}
        style={{ textAlign: "center" }}
        open={props.isModalChangeLicenseDateOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <DatePicker
          disabledDate={disabledDate}
          onChange={(value) => setSelectedDate(value)}
        />
      </Modal>
    </>
  );
};
export default ModalChangeLicenseDate;

import React from "react";
import CreateForm from "../components/trade-agents/CreateForm";

export default function CreateTradeAgent() {
  return (
    <>
      <CreateForm></CreateForm>
    </>
  );
}

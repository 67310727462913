export const getServiceSettingsObject = (settings, service) => {
  const serviceSettings = settings.filter((sett) => sett.service === service);

  const revisionSettingsObject = serviceSettings.reduce(
    (acc, cur) => ({ ...acc, [cur.name]: cur.value }),
    {}
  );

  return revisionSettingsObject;
};

export const getServiceSettingsArray = (settings, service) => {
  const serviceSettings = settings.filter((sett) => sett.service === service);

  //   const settingsArray = serviceSettings.reduce((acc, cur) => [...acc, cur], []);

  return serviceSettings;
};

import { Checkbox, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { getServiceSettingsObject } from "./functions";
import { serviceTitlesInSettings } from "../../../CONSTANTS/serviceTitles";
import { onChangeSetting } from "../../api/settings/settings";

const SERVICE = serviceTitlesInSettings.ORDERS;

export default function OrdersSettings({ settings, users }) {
  const [useDefaultTableType, setUseDefaultTableType] = useState(false);
  const [useCheckingOrdersBarcodes, setUseCheckingOrdersBarcodes] =
    useState(false);
  const [dontRewriteOrders, setDontRewriteOrders] = useState(false);

  const CHECKBOX_TO_SETTER = {
    useDefaultTableType: setUseDefaultTableType,
    useCheckingOrdersBarcodes: setUseCheckingOrdersBarcodes,
    dontRewriteOrders: setDontRewriteOrders,
  };

  useEffect(() => {
    if (settings) {
      setSettingsData();
    }
  }, []);

  const setSettingsData = async () => {
    const settingsObj = getServiceSettingsObject(
      settings,
      serviceTitlesInSettings.ORDERS
    );
    setUseDefaultTableType(settingsObj.useDefaultTableType);
    setUseCheckingOrdersBarcodes(settingsObj.useCheckingOrdersBarcodes);
    setDontRewriteOrders(settingsObj.dontRewriteOrders);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Checkbox
            name="useDefaultTableType"
            checked={useDefaultTableType}
            onChange={(e) =>
              onChangeSetting(
                SERVICE,
                e.target.name,
                e.target.checked,
                CHECKBOX_TO_SETTER,
                users
              )
            }
          >
            Стандартний вигляд журналу документів
          </Checkbox>
        </Col>
        <Col span={24}>
          <Checkbox
            name="useCheckingOrdersBarcodes"
            checked={useCheckingOrdersBarcodes}
            onChange={(e) =>
              onChangeSetting(
                SERVICE,
                e.target.name,
                e.target.checked,
                CHECKBOX_TO_SETTER,
                users
              )
            }
          >
            Використовувати функціонал для контролю замовлень
          </Checkbox>
        </Col>
        <Col span={24}>
          <Checkbox
            name="dontRewriteOrders"
            checked={dontRewriteOrders}
            onChange={(e) =>
              onChangeSetting(
                SERVICE,
                e.target.name,
                e.target.checked,
                CHECKBOX_TO_SETTER,
                users
              )
            }
          >
            Не перезаписувати замовлення при імпорті
          </Checkbox>
        </Col>
      </Row>
    </>
  );
}

import { Checkbox, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { getServiceSettingsObject } from "./functions";
import { serviceTitlesInSettings } from "../../../CONSTANTS/serviceTitles";
import { onChangeSetting } from "../../api/settings/settings";

const SERVICE = serviceTitlesInSettings.VERIFICATION;

export default function VerificationSettings({ settings, users }) {
  const [dontRewriteDocuments, setDontRewriteDocuments] = useState(false);
  const [forbidExceedQuantity, setForbidExceedQuantity] = useState(false);

  const CHECKBOX_TO_SETTER = {
    dontRewriteDocuments: setDontRewriteDocuments,
    forbidExceedQuantity: setForbidExceedQuantity,
  };

  useEffect(() => {
    if (settings) {
      setSettingsData();
    }
  }, []);

  const setSettingsData = async () => {
    const settingsObj = getServiceSettingsObject(
      settings,
      serviceTitlesInSettings.VERIFICATION
    );
    setDontRewriteDocuments(settingsObj.dontRewriteDocuments);
    setForbidExceedQuantity(settingsObj.forbidExceedQuantity);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Checkbox
            name="dontRewriteDocuments"
            checked={dontRewriteDocuments}
            onChange={(e) =>
              onChangeSetting(
                SERVICE,
                e.target.name,
                e.target.checked,
                CHECKBOX_TO_SETTER,
                users
              )
            }
          >
            Не перезаписувати документи при імпорті
          </Checkbox>
          <br></br>
          <Checkbox
            name="forbidExceedQuantity"
            checked={forbidExceedQuantity}
            onChange={(e) =>
              onChangeSetting(
                SERVICE,
                e.target.name,
                e.target.checked,
                CHECKBOX_TO_SETTER,
                users
              )
            }
          >
            Заборонити перевищувати кількість у документі
          </Checkbox>
        </Col>
      </Row>
    </>
  );
}

import { createContext, useState } from "react";
import React from "react";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const loggedInUser = sessionStorage.getItem("user");

  const [user, setUser] = useState(
    loggedInUser == undefined ? null : JSON.parse(loggedInUser)
  );

  const signIn = (newUser, cb) => {
    setUser(newUser);
    cb();
  };
  const signOut = (cb) => {
    setUser(null);
    sessionStorage.removeItem("user");
    cb();
  };

  const value = { user, signIn, signOut };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

import React from "react";
import { Button, DatePicker, Row, Col } from "antd";

export const DateFilterDropDown = (props) => {
  return (
    <>
      <Row>
        <Col>Відбере користувачів до вибраної дати</Col>
      </Row>

      <DatePicker
        defaultOpen={true}
        format={"DD.MM.YYYY"}
        value={props.selectedKeys[0]}
        onChange={(e) => {
          props.setSelectedKeys(e ? [e] : []);
        }}
      ></DatePicker>
      <Button
        type="primary"
        onClick={() => {
          props.confirm();
        }}
      >
        Фільтр
      </Button>
      <Button
        type="danger"
        onClick={() => {
          props.clearFilters();
          props.confirm();
        }}
      >
        Скинути
      </Button>
    </>
  );
};

import React, { useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  message,
} from "antd";
import dayjs from "dayjs";
import { editUser } from "../../components/api/db-api";
import { useNavigate } from "react-router";

export default function EditForm({ user }) {
  const navigate = useNavigate();

  const onFinish = async (values) => {
    console.log("Success:", values);

    const result = await editUser(values);

    if (result?.status === "success") {
      message.success("Успішно!");
      navigate("/", { state: { updateTable: false } });
    } else {
      message.error("Помилка оновлення користувача. " + result?.message + "");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      //   deviceCount: user.deviceCount,
      //   ipAddress: user.ipAddress,
      username: user.username,
      expirationDate: dayjs(user.expiration_date),
      deviceCount: user.num_devices,
      id: user.id,
    });
  }, [user]);

  return (
    <>
      <Row justify={"center"}>
        <Form
          layout="vertical"
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ justifyContent: "center" }}
          wrapperCol={{
            span: 20,
          }}
          form={form}
        >
          <Row
            style={{
              // textAlign: "center",
              justifyContent: "center",
              margin: "0 auto",
            }}
          >
            <Col span={8}>
              <Form.Item
                label="Логін"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Пароль"
                name="password"
                rules={[
                  {
                    // required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password autoComplete="new-password" />
              </Form.Item>

              <Form.Item
                label="id"
                name="id"
                rules={[
                  {
                    required: true,
                    type: "id",
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                name="expirationDate"
                label="Доступ до"
              >
                <DatePicker disabledDate={disabledDate} />
              </Form.Item>

              <Form.Item name="deviceCount" label="К-ть пристроїв">
                <InputNumber min={1}></InputNumber>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Col>
              <Form.Item>
                <Button size="large" type="primary" htmlType="submit">
                  Оновити
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
}

import React from "react";
import { Outlet } from "react-router-dom";
import HeadMenu from "./header/HeadMenu";
import { Row, Col } from "antd";

export default function Layout() {
  return (
    <>
      <HeadMenu></HeadMenu>
      <Row style={{ margin: "20px 5px" }}>
        <Col span={24}>
          <Outlet />
        </Col>
      </Row>
    </>
  );
}

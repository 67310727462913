import React from "react";
import MultipleEditForm from "../components/users/edit/MultipleEditForm";
import MultipleEditSettingsForm from "../components/users/edit/MultipleEditSettingsForm";
import { useLocation } from "react-router-dom";
import { Form, Checkbox, Row, Col, Tabs } from "antd";

export default function MultipleEdit() {
  const location = useLocation();
  const users = location.state?.users;
  const onChange = (key) => {
    // console.log(key);
  };

  const items = [
    {
      key: "1",
      label: `Дані`,
      children: <MultipleEditForm users={users}></MultipleEditForm>,
    },
    {
      key: "2",
      label: `Налаштування`,
      children: (
        <MultipleEditSettingsForm users={users}></MultipleEditSettingsForm>
      ),
    },
  ];
  const formTitle = `Зміна користувачів: ${users.map(
    (user) => user.login + ""
  )}`;

  return (
    <>
      <Row style={{ justifyContent: "center" }}>
        <Col>
          <h3>{formTitle}</h3>
        </Col>
      </Row>
      <Row>
        <Col span={20} offset={4}>
          <Tabs defaultActiveKey="1" items={items} onChange={onChange}></Tabs>
        </Col>
      </Row>
    </>
  );
}

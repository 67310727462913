import { Navigate, useLocation, useNavigate } from "react-router-dom";

import React from "react";
import { useAuth } from "../hook/useAuth";

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { user } = useAuth();
  // console.log(user);
  if (!user) {
    return <Navigate to="/login" state={{ from: location }}></Navigate>;
  }
  return children;
};

export default RequireAuth;

import { message } from "antd";
import { updateUsersSetting } from "../db-api";

export const onChangeSetting = async (
  service,
  settName,
  settVal,
  CHECKBOX_TO_SETTER,
  users
) => {
  const sett = {
    service,
    name: settName,
    value: settVal,
  };

  console.log(sett);

  const result = await updateUsersSetting(
    users.map((user) => user.id),
    sett
  );

  const resultObj = result.reduce((acc, cur) => ({ ...acc, ...cur }), {});
  const updateStatus = resultObj.updateStatus;
  const addStatus = resultObj.addStatus;

  if (updateStatus?.status === "error" || addStatus?.status === "error") {
    return message.error("Помилка при зміні налаштувань!");
  }

  if (updateStatus?.status) {
    message.info(updateStatus?.message);
  }
  if (addStatus?.status) {
    message.info(addStatus?.message);
  }

  const setter = CHECKBOX_TO_SETTER[sett.name];
  setter(sett.value);
};

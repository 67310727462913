import "./App.css";
import { Routes, Route } from "react-router-dom";
import Mainpage from "./pages/Mainpage";
import Layout from "./components/Layout.js";
import Nopage from "./pages/Nopage";
import Createuser from "./pages/Createuser";
import TradeAgents from "./pages/TradeAgents";
import Edituser from "./pages/Edituser";
import Loginpage from "./pages/Loginpage";
import RequireAuth from "./hoc/RequireAuth";
import { AuthProvider } from "./hoc/AuthProvider";
import MultipleEdit from "./pages/MultipleEdit";
import CreateTradeAgent from "./pages/CreateTradeAgent.js";
import EditTaUser from "./pages/EditTaUser.js";
import WaappStatistics from "./pages/WaappStatistics.js";
function App() {
  // return "awd";
  return (
    <>
      <AuthProvider>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <Layout></Layout>
              </RequireAuth>
            }
          >
            <Route index element={<Mainpage />} />
            <Route path="waapp-statistics" element={<WaappStatistics />} />
            <Route path="add-user" element={<Createuser />} />
            <Route path="trade-agents" element={<TradeAgents />} />
            <Route path="trade-agents/create" element={<CreateTradeAgent />} />
            <Route path="edit-user/:id" element={<Edituser />} />
            <Route path="edit-ta-user/:id" element={<EditTaUser />} />
            <Route path="multiple-edit-users" element={<MultipleEdit />} />
            <Route path="*" element={<Nopage />} />
          </Route>
          <Route path="/login" element={<Loginpage></Loginpage>}></Route>
        </Routes>
      </AuthProvider>
    </>
  );
}

export default App;

import {
  Form,
  Checkbox,
  Row,
  Col,
  Button,
  DatePicker,
  Input,
  message,
  InputNumber,
  Radio,
} from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import dayjs from "dayjs";
import { editUsers } from "../../api/db-api";

export default function MultipleEditForm(props) {
  const navigate = useNavigate();

  const users = props.users;

  const onFinish = async (values) => {
    console.log("Success:", values);
    const newValues = getNewValues(values);
    if (Object.keys(newValues).length === 0) {
      return message.error("Нічого не введено");
    }
    // треба передавати всі ролі, неактивні теж
    const result = await editUsers(
      users.map((user) => user.id),
      newValues
    );
    console.log(result);
    if (result?.status === "success") {
      message.success("Успішно!");
      navigate("/", { state: { updateTable: true } });
    } else {
      message.error("Помилка оновлення користувача. " + result?.message + "");
    }
  };

  function getNewValues(values) {
    let newValues = values;
    for (const [key, value] of Object.entries(values)) {
      console.log(`${key}: ${value}`);
      if (!value) {
        delete newValues[key];
      }
    }
    return newValues;
  }
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };

  return (
    <>
      <Form
        layout="vertical"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{ justifyContent: "center" }}
        wrapperCol={{
          span: 20,
        }}
      >
        <Row
          style={{
            width: "80%",
            // textAlign: "center",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          <Col span={8}>
            <Form.Item
              label="Пароль"
              name="password"
              rules={[
                {
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Компанія"
              name="company"
              rules={[
                {
                  message: "Введіть компанію",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Іп адреса:порт"
              name="ipAddress"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="expirationDate" label="Доступ до">
              <DatePicker disabledDate={disabledDate} />
            </Form.Item>
            <Form.Item
              label="E-mail"
              name="email"
              rules={[
                {
                  message: "Please input your username!",
                  type: "email",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="deviceCount" label="К-ть пристроїв">
              <InputNumber min={1}></InputNumber>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="roles" label="Доступні ролі">
              <Checkbox.Group>
                <Row>
                  <Col span={24}>
                    <Checkbox value="inventory">Ревізія</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="invoice">Прихід</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="perem">Переміщення</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="order">Замовлення</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="sales">Реалізація</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="checkPrice">Перевірка цін</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="barcoding">Штрихкодування</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item name="exchangeType">
              <Radio.Group>
                <Radio value={"xml"}>XML</Radio>
                <Radio value={"txt"}>TXT</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ justifyContent: "center" }}>
          <Col>
            <Form.Item>
              <Button size="large" type="primary" htmlType="submit">
                Оновити
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

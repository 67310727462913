import React from "react";
import CreateForm from "../components/users/CreateForm";

export default function Createuser() {
  return (
    <>
      <CreateForm></CreateForm>
    </>
  );
}

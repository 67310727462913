import React, { useState, useRef } from "react";
import { Table, Input, Space, Button, message, Popconfirm } from "antd";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import {
  MobileOutlined,
  UserOutlined,
  SearchOutlined,
  MobileFilled,
  CalendarOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { ModalActions } from "../../components/mainpage/ModalActions";
import { DateFilterDropDown } from "../../components/filters/DateFilterDropDown";
import { ModalChangeLicenseDate } from "./ModalChangeLicenseDate";
import { deleteUserSessionAPI } from "../api/db-api";

export default function UsersTable(props) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [user, setUser] = useState();
  const [isModalActionsOpen, setIsModalActionsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const searchInput = useRef(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isModalChangeLicenseDateOpen, setIsModalChangeLicenseDateOpen] =
    useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [activeUserSessions, setActiveUserSessions] = useState();

  const navigate = useNavigate();

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          size={"large"}
          ref={searchInput}
          placeholder={`Введіть текст`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Шукати
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Очистити
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const onRowAction = (record, rowIndex) => {
    // console.log(record);
    setIsModalActionsOpen(true);
    setUser(record);
  };

  const columns = [
    {
      title: "№",
      key: "№",
      dataIndex: "key",
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: <UserOutlined />,
      dataIndex: "login",
      key: "login",
      align: "center",
      sorter: (a, b) => {
        return a.login.localeCompare(b.login);
      },
      ...getColumnSearchProps("login"),
    },
    {
      title: <CalendarOutlined />,
      dataIndex: "expiration_date",
      key: "expiration_date",
      align: "center",
      render: (_, record) => {
        const date = dayjs(record.expiration_date).format("DD.MM.YYYY");
        return date;
      },
      sorter: (a, b) =>
        new Date(a.expiration_date) - new Date(b.expiration_date),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <DateFilterDropDown
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
          />
        );
      },
      filterIcon: () => {
        return <SearchOutlined></SearchOutlined>;
      },
      onFilter: (value, record) => {
        return record.expiration_date
          ? dayjs(record.expiration_date).isBefore(value[0], "day")
          : "";
      },
    },
    {
      title: "Компанія",
      dataIndex: "company",
      key: "company",
      align: "center",
      ...getColumnSearchProps("company"),
    },
    {
      title: <MobileOutlined />,
      dataIndex: "num_devices",
      align: "center",
      sorter: (a, b) => a.num_devices - b.num_devices,
    },
    {
      title: <MobileFilled />,
      dataIndex: "used_devices",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) => {
        const len1 = a?.sessions?.length ? a.sessions?.length : 0;
        const len2 = b?.sessions?.length ? b.sessions?.length : 0;
        return len1 - len2;
      },
      render: (_, record) => {
        return record.sessions?.length;
      },
    },

    {
      title: "Тип обміну",
      dataIndex: "exchangeType",
      align: "center",
      sorter: (a, b) => a.exchangeType.localeCompare(b.exchangeType),
    },
  ];

  const deleteUserSession = async (record) => {
    const result = await deleteUserSessionAPI(
      +record.user_id,
      record.session_id
    );
    if (!result) return message.error("Помилка видалення сесії");

    const newUserSessions = activeUserSessions.filter(
      (session) => session.session_id !== record.session_id
    );
    setActiveUserSessions(newUserSessions);

    if (!newUserSessions.length) {
      setExpandedRowKeys([]);
    }

    const currentUser = props.users.find((user) => user.id === +record.user_id);
    currentUser.sessions = newUserSessions;
    // const newUsers = props.users.filter((user) => user.id !== currentUser.id);
    // props.setUsers([...newUsers, currentUser]);
  };

  const expandedRowRender = (row) => {
    const childColumns = [
      {
        title: "Ід сесії",
        dataIndex: "session_id",
        width: "20%",
        key: "session_id",
      },
      {
        title: "Час створення",
        dataIndex: "login_time",
        key: "login_time",
        width: "15%",
      },
      {
        title: "IP-адреса",
        dataIndex: "ip_address",
        key: "ip_address",
        width: "15%",
      },
      {
        title: "Агент",
        dataIndex: "user_agent",
        key: "user_agent",
      },
      {
        title: <DeleteOutlined />,
        key: "remove",
        dataIndex: "user_id",
        width: "10%",
        render: (_, record) => {
          return (
            <Popconfirm
              title={"Видалити?"}
              key={record.key}
              onConfirm={() => deleteUserSession(record)}
            >
              <DeleteOutlined />
            </Popconfirm>
          );
        },
      },
    ];

    return (
      <Table
        columns={childColumns}
        dataSource={activeUserSessions}
        pagination={false}
        tableLayout="fixed"
        rowClassName={(record, index) => {
          return record.imported === "true" ? "imported" : "";
        }}
      />
    );
  };

  const onTableRowExpand = (expanded, record) => {
    setActiveUserSessions(
      record.sessions.map((session) => {
        return { ...session, key: session.session_id };
      })
    );

    const keys = [];
    if (expanded) {
      keys.push(record.key);
    }
    setExpandedRowKeys(keys);
  };

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedUsers(
      selectedRows.map((user) => ({ id: user.id, login: user.login }))
    );
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <>
      <Button
        onClick={() => {
          navigate("add-user");
        }}
      >
        Створити користувача
      </Button>
      <Button
        onClick={() => {
          if (!selectedUsers.length) {
            return message.error("Виберіть користувачів");
          }
          navigate("multiple-edit-users", { state: { users: selectedUsers } });
        }}
      >
        Масове редагування
      </Button>
      <Button
        onClick={() => {
          if (!selectedUsers.length) {
            return message.error("Виберіть користувачів");
          }
          setIsModalChangeLicenseDateOpen(true);
        }}
      >
        Змінити термін дії діцензії
      </Button>
      <Table
        loading={props.loading}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              onRowAction(record);
            },
          };
        }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={props.users}
        bordered={true}
        pagination={{ position: ["bottomCenter"], hideOnSinglePage: true }}
        expandable={{
          expandedRowRender,
          expandRowByClick: false,
          expandedRowKeys,
          onExpand: onTableRowExpand,
          rowExpandable: (record) => record?.sessions,
        }}
      />
      <ModalActions
        isModalActionsOpen={isModalActionsOpen}
        setIsModalActionsOpen={setIsModalActionsOpen}
        user={user}
        users={props.users}
        setUsers={props.setUsers}
      ></ModalActions>
      <ModalChangeLicenseDate
        isModalChangeLicenseDateOpen={isModalChangeLicenseDateOpen}
        setIsModalChangeLicenseDateOpen={setIsModalChangeLicenseDateOpen}
        users={selectedUsers}
      ></ModalChangeLicenseDate>
    </>
  );
}

import React, { useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Checkbox,
  message,
  Radio,
} from "antd";
import dayjs from "dayjs";
import { createUser } from "../../components/api/db-api";
import { useNavigate } from "react-router";

export default function CreateForm() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ deviceCount: 1 });
  }, []);

  const onFinish = async (values) => {
    const result = await createUser(values);

    if (result?.status === "success") {
      message.success("Успішно!");
      navigate("/", { state: { updateTable: true } });
    } else {
      message.error("Помилка додавання користувача. " + result?.message + "");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };

  return (
    <>
      <Row justify={"center"}>
        <Form
          layout="vertical"
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          wrapperCol={{
            span: 20,
          }}
          form={form}
        >
          <Row>
            <Col span={8}>
              <Form.Item
                label="Логін"
                name="login"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Пароль"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="Компанія"
                name="company"
                rules={[
                  {
                    required: true,
                    message: "Введіть компанію",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Іп адреса:порт"
                name="ipAddress"
                rules={[
                  {
                    required: false,
                    type: "string",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                name="expirationDate"
                label="Доступ до"
              >
                <DatePicker disabledDate={disabledDate} />
              </Form.Item>
              <Form.Item
                label="E-mail"
                name="email"
                rules={[
                  {
                    required: false,
                    message: "Please input your username!",
                    type: "email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="roles" label="Доступні ролі">
                <Checkbox.Group>
                  <Row>
                    <Col span={24}>
                      <Checkbox value="inventory">Ревізія</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="invoice">Прихід / Повернення</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="perem">Переміщення</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="order">Замовлення</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="sales">Реалізація</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="checkPrice">Перевірка цін</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="barcoding">Штрихкодування</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item name="exchangeType">
                <Radio.Group>
                  <Radio value={"xml"} checked>
                    XML
                  </Radio>
                  <Radio value={"txt"}>TXT</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={"center"}>
            <Col>
              <Form.Item name="deviceCount" label="К-ть пристроїв">
                <InputNumber min={1} defaultValue={1}></InputNumber>
              </Form.Item>
              <Form.Item>
                <Button size="large" type="primary" htmlType="submit">
                  Створити
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
}

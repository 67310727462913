import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import EditForm from "../components/users/EditForm";
import { Col, Row, Spin, Tabs } from "antd";
import SettingsForm from "../components/users/SettingsForm";
import { getUserById } from "../components/api/db-api";

export default function Edituser() {
  const params = useParams();
  const [user, setUser] = useState({});
  const [spinning, setSpinning] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    if (params?.id) {
      setUser(await getUserById(params.id));
      setSpinning(false);
    }
  };

  const onChange = (key) => {
    // console.log(key);
  };

  const items = [
    {
      key: "1",
      label: `Дані`,
      children: <EditForm user={user}></EditForm>,
    },
    {
      key: "2",
      label: `Налаштування`,
      children: <SettingsForm user={user}></SettingsForm>,
    },
  ];

  return (
    <>
      <Row>
        <Col span={22} offset={2}>
          <Spin spinning={spinning}>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange}></Tabs>
          </Spin>
        </Col>
      </Row>
    </>
  );
}
